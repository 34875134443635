// PasswordPage.scss

$password-page-bg-color: #222;
$password-page-text-color: #fff;
$password-input-border-color: #444;
$password-input-focus-color: #fff;
$button-bg-color: #444;
$button-hover-bg-color: #555;

.password-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $password-page-bg-color;
  color: $password-page-text-color;
  text-align: center;
  font-family: Arial, sans-serif;

  .content {
    max-width: 500px;
    width: 90%;
    padding: 2rem;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);

    h2 {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      opacity: 0.8;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input[type="password"] {
        padding: 0.75rem;
        font-size: 1rem;
        color: $password-page-text-color;
        background: transparent;
        border: 2px solid $password-input-border-color;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
          border-color: $password-input-focus-color;
        }
      }

      button {
        padding: 0.75rem;
        font-size: 1rem;
        font-weight: bold;
        color: $password-page-text-color;
        background-color: $button-bg-color;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: $button-hover-bg-color;
        }
      }
    }
  }
}
